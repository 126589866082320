/* eslint-disable @typescript-eslint/no-explicit-any */
import { createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';
import { omit } from 'lodash';
import website from './modules/website';
import cart from './modules/cart/index';
import product from './modules/product';
import productList from './modules/productList';
import auth from './modules/auth/index';
import metaTags from './modules/metatags';

const strict = process.env.NODE_ENV !== 'production';

export default createStore({
  modules: {
    website,
    cart,
    product,
    productList,
    auth,
    metaTags,
  },
  strict,
  plugins: [
    new VuexPersistence<any>({
      storage: window.sessionStorage,
      reducer: (state) => ({ ...omit(state, 'metaTags') }),
    }).plugin,
  ],
});
