/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { CancelToken } from 'axios';
import { contactFormPayload, HiddenFields } from '@/models/product/contactFormHiddenFields';
import { ProductListLiveDataPayload, ProductListPayload, ProductPayload } from '@/models/productList/productListPayloadModels';
import {
  getProductFieldSearchParams,
  getProductListSearchParams,
  getProductSearchParams,
  getProductVariantsSearchParams,
  getRelatedProductsSearchParams,
} from '@/utils/requestHelper';
import loggingApi from './loggingApi';
import conf from '@/config';

function logError(e: unknown) {
  if (axios.isAxiosError(e)) {
    const msg = e.stack || e.message;
    if (e.response?.status && e.response.status >= 500) {
      loggingApi.postEmergency(msg);
    } else {
      loggingApi.postError(msg);
    }
  }
}

class ProductAPI {
  static getUrl(): string {
    return `${conf.dwUrl}/dwapi/ecommerce/products`;
  }

  static getConfig(cancelToken?: CancelToken): any {
    return {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      cancelToken,
      withCredentials: true,
    };
  }

  static async getProductList(payload: ProductListPayload): Promise<any> {
    let response;
    try {
      const config = this.getConfig(payload.cancelToken);
      const data = getProductListSearchParams(payload);
      const groupidParameter = payload.params.groupID !== '' ? `&GroupID=${payload.params.groupID}` : '';
      const offerParameter = payload.params.showOnlyOffers ? '&Tilbod=1' : '';
      const outletParameter = payload.params.showOnlyOutlet ? '&Toppskor=Yes' : '';
      const brandNameParameter = payload.params.brandName !== undefined && payload.params.brandName !== '' ? `&BrandName=${payload.params.brandName}` : '';
      const productIdsParameter = payload.params.productIds !== '' && payload.params.productIds !== undefined ? `&Products=${payload.params.productIds}` : '';
      const url = `${this.getUrl()}?RepositoryName=${payload.params.repositoryName}&QueryName=${
        payload.params.queryName
      }${groupidParameter}${offerParameter}${outletParameter}${brandNameParameter}${productIdsParameter}${payload.params.filterQuery}`;
      response = await axios.post(url, data, config);
      return response;
    } catch (e) {
      if (!axios.isCancel(e)) {
        // Log Error
      }
    }
    return {};
  }

  static async getProductListLiveData(payload: ProductListLiveDataPayload): Promise<any> {
    let response;
    try {
      const config = this.getConfig(payload.cancelToken);
      const url = `${conf.dwUrl}/${conf.area}/product-feed?Products=${payload.productIds}&ShopID=${conf.shop}`;
      response = await axios.post(url, {}, config);
      return response;
    } catch (e) {
      if (!axios.isCancel(e)) {
        logError(e);
      }
    }
    return {};
  }

  static async getProduct(payload: ProductPayload): Promise<any> {
    let response;

    if (payload.productId === undefined) {
      return response;
    }

    try {
      const config = {
        params: getProductSearchParams(),
        cancelToken: payload.cancelToken,
        withCredentials: true,
      };

      const url = `${this.getUrl()}/${payload.productId}`;
      response = await axios.get(url, config);
      return response;
    } catch (e) {
      if (!axios.isCancel(e)) {
        // Log Error
      }
    }
    return {};
  }

  static async getproductLiveData(payload: ProductPayload): Promise<any> {
    let response;

    if (payload.productId === undefined) {
      return response;
    }

    try {
      const config = {
        cancelToken: payload.cancelToken,
        withCredentials: true,
      };

      const url = `${conf.dwUrl}/${conf.area}/product-feed?ProductID=${payload.productId}&ShopID=${conf.shop}`;
      response = await axios.get(url, config);
    } catch (e) {
      if (!axios.isCancel(e)) {
        logError(e);
      }
    }
    return response;
  }

  static async getRelatedProducts(payload: ProductPayload): Promise<any> {
    let response;

    if (payload.productId === undefined) {
      return response;
    }

    try {
      const config = {
        params: getRelatedProductsSearchParams(),
        cancelToken: payload.cancelToken,
        withCredentials: true,
      };

      const url = `${this.getUrl()}/${payload.productId}/related`;
      response = await axios.get(url, config);
    } catch (e) {
      if (!axios.isCancel(e)) {
        // Log Error
      }
    }
    return response;
  }

  static async getRelatedProductsLiveData(payload: ProductPayload): Promise<any> {
    let response;

    if (payload.productId === undefined) {
      return response;
    }

    try {
      const config = {
        cancelToken: payload.cancelToken,
        withCredentials: true,
      };
      const url = `${conf.dwUrl}/${conf.area}/related-products-feed?ProductID=${payload.productId}`;
      response = await axios.get(url, config);
    } catch (e) {
      if (!axios.isCancel(e)) {
        logError(e);
      }
    }
    return response;
  }

  static async getProductListRelatedProductsLiveData(payload: ProductListLiveDataPayload): Promise<any> {
    let response;

    try {
      const config = {
        cancelToken: payload.cancelToken,
        withCredentials: true,
      };

      const url = `${conf.dwUrl}/${conf.area}/related-products-feed?Products=${payload.productIds}`;
      response = await axios.get(url, config);
    } catch (e) {
      if (!axios.isCancel(e)) {
        logError(e);
      }
    }
    return response;
  }

  static async getProductVariants(payload: ProductPayload): Promise<any> {
    let response;

    if (payload.productId === undefined) {
      return response;
    }

    try {
      const config = {
        params: getProductVariantsSearchParams(),
        cancelToken: payload.cancelToken,
        withCredentials: true,
      };

      const url = `${this.getUrl()}/${payload.productId}`;
      response = await axios.get(url, config);
    } catch (e) {
      if (!axios.isCancel(e)) {
        // Log Error
      }
    }
    return response;
  }

  static async getProductFields(productId: string): Promise<any> {
    // TODO - FIX LOOPEDYLOOP
    let response;

    if (productId === undefined) {
      return response;
    }

    try {
      const config = {
        params: getProductFieldSearchParams(),
        withCredentials: true,
      };

      const url = `${this.getUrl()}/${productId}`;
      response = await axios.get(url, config);
    } catch (e) {
      if (!axios.isCancel(e)) {
        // Log Error
      }
    }
    return response;
  }

  static async getContactFormHiddenFields(): Promise<HiddenFields[]> {
    let response;
    // Call contact form url (fetch second part of url from website settings)
    const url = `${conf.dwUrl}/${conf.area}/contact-form`;

    try {
      response = await axios.get(url);
      response = response?.data?.hiddenFormFields;
    } catch (e) {
      if (!axios.isCancel(e)) {
        // Log Error
      }
    }
    return response;
  }

  static async postContactForm(payload: contactFormPayload): Promise<any> {
    let response;

    if (payload.productID === undefined) {
      console.error(payload);
      return response;
    }

    try {
      const config = this.getConfig(payload.cancelToken);
      const formData = new FormData();
      payload.hiddenFields.forEach((hiddenField) => formData.append(hiddenField.name, hiddenField.value));
      formData.append('NewHafaSamband_ProductID', payload.productID);
      formData.append('NewHafaSamband_Nafn', payload.name);
      formData.append('NewHafaSamband_Email', payload.email);
      formData.append('NewHafaSamband_Skilabod', payload.message);
      formData.append('NewHafaSamband_WebEmail', payload.webEmail);
      formData.append('NewHafaSamband_Kennitala', payload.kennitala);
      const url = `${conf.dwUrl}/${conf.area}/contact-form`;
      await axios.post(url, formData, config);
    } catch (e) {
      if (!axios.isCancel(e)) {
        // Log Error
      }

      console.error(e);
    }

    return response;
  }
}

export default ProductAPI;
