
  import { defineComponent } from 'vue';

  import useAuthenticateUser from '@/composables/useAuthenticateUser';

  import useWebsiteSettings from '@/composables/useWebsiteSettings';
  import TextInput from '@/components/shared/inputs/TextInput.vue';
  import Loader from '@/components/shared/Loader.vue';
  import CustomButton from '@/components/shared/Button.vue';

  export default defineComponent({
    components: {
      TextInput,
      Loader,
      CustomButton,
    },
    emits: ['back'],
    setup() {
      const { passwordRecoveryInfo, changePassword } = useAuthenticateUser();
      const { updateWebsiteSettings } = useWebsiteSettings();
      return { passwordRecoveryInfo, changePassword, updateWebsiteSettings };
    },
    data() {
      return {
        formData: {
          password: '',
          confirmPassword: '',
        },
        error: '',
        initLoading: true,
        loading: false,
        emailSent: false,
        userFound: false,
        tokenOutOfDate: false,
        action: '',
        token: '',
      };
    },
    computed: {
      dwFormName() {
        return {
          password: () => 'UserManagement_Form_NewPassword',
          confirmPassword: () => 'UserManagement_Form_NewPasswordConfirm',
        };
      },
      showPasswordResetForm() {
        return this.action === 'NewPasswordForm' && this.userFound === true && this.tokenOutOfDate === false;
      },
    },
    mounted() {
      this.getPasswordRecoveryInfo();
      this.updateWebsiteSettings();
    },
    methods: {
      onSubmit() {
        this.error = '';
        if (this.formData.password === '' || this.formData.confirmPassword === '') {
          this.error = 'Gefa verður upp lykilorð';
        } else if (this.formData.confirmPassword !== this.formData.password) {
          this.error = 'Lykilorðin eru ekki eins';
        } else {
          this.changePasswordAttempt();
        }
      },
      async getPasswordRecoveryInfo() {
        const { token } = this.$route.query;
        this.token = token?.toString() ?? '';
        if (token) {
          const passwordRecoveryInfo = await this.passwordRecoveryInfo(token.toString());
          this.action = passwordRecoveryInfo?.data?.Action;
          this.userFound = passwordRecoveryInfo?.data?.FoundUser;
          this.tokenOutOfDate = passwordRecoveryInfo?.data?.RecoveryTokenOutOfDate;
        }
        this.initLoading = false;
      },
      async changePasswordAttempt() {
        this.loading = true;
        const response = await this.changePassword(this.formData.password, this.formData.confirmPassword, this.token);
        this.action = response?.data?.Action;
        this.loading = false;
      },
    },
  });
