
  import { computed, defineComponent, reactive, ref } from 'vue';

  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import * as droppHelper from './droppHelper';

  import useCart from '@/composables/useCart';

  import { minLength, validatePostCode } from '@/utils/formValidators';

  import { DeliveryInfo } from '@/models/cart/deliveryInfo';
  import { postboxPayload } from '@/models/cart/shippingMethod';
  import { CartState } from '@/models/store/cart';

  import CartSideOverviewOrderLines from '@/components/cart/checkout/shared/CartSideOverviewOrderLines.vue';
  import RadioButtonInput from '@/components/shared/inputs/RadioButtonInput.vue';
  import CartBackAndNextButtons from '@/components/cart/checkout/shared/CartBackAndNextButtons.vue';
  import TextInput from '@/components/shared/inputs/TextInput.vue';
  import CustomButton from '@/components/shared/Button.vue';
  import AdvaniaIntegratedShippingMethod from './AdvaniaIntegratedShippingMethod.vue';
  import useAdvaniaIntegratedShippingMethod from '@/composables/useAdvaniaIntegratedShippingMethod';
  import { GA4E } from '@/ga4ecommerce/GA4E';

  export default defineComponent({
    components: {
      CartSideOverviewOrderLines,
      RadioButtonInput,
      CartBackAndNextButtons,
      TextInput,
      CustomButton,
      AdvaniaIntegratedShippingMethod,
    },
    setup() {
      const router = useRouter();
      const store = useStore();
      const state = store.state.cart as CartState;
      const cartLoading = computed(() => state.loading);

      const droppBoxID = 'DW_DROPP_BOXES';
      const postBoxID = 'DW_POSTBOXES';
      const homeDeliveryID = 'DW_HOME_DELIVERY';
      const nearestPostOfficeID = 'DW_NEAREST_POSTOFFICE';

      const {
        shippingMethods,
        selectShippingMethod,
        selectedShippingMethod,
        selectedShippingMethodObject,
        selectedShippingID,
        selectPostBox,
        selectedPostBox,
        postShippingMethod,
        setDeliveryInfo,
        postDeliveryInfo,
        getDeliveryInfo,
        getCustomerInfo,
        orderLines,
      } = useCart();

      const { selectAdvaniaShippingAgent, selectedShippingMethodAgent } = useAdvaniaIntegratedShippingMethod();
      const onAdvaniaShippingAgentSelect = (agent) => {
        selectedShippingMethodAgent.value = agent;
      };

      const droppDeliveryInfo = {} as DeliveryInfo;
      droppDeliveryInfo.DroppLocationName = getDeliveryInfo.value.DroppLocationName;
      droppDeliveryInfo.DroppLocationID = getDeliveryInfo.value.DroppLocationID;

      const deliveryAddressInputs = reactive({
        name: {
          value: getDeliveryInfo.value.DeliveryName || getCustomerInfo.value?.CustomerName || '',
          validators: [minLength(1, 'Nafn má ekki vera tómt')],
        },
        phone: {
          value: getDeliveryInfo.value.DeliveryPhone || getCustomerInfo.value?.CustomerPhone || '',
          validators: [minLength(1, 'Símanúmer má ekki vera tómt')],
        },
        address: {
          value: getDeliveryInfo.value.DeliveryAddress || getCustomerInfo.value?.CustomerAddress || '',
          validators: [minLength(1, 'Heimilisfang má ekki vera tómt')],
        },
        postcode: {
          value: getDeliveryInfo.value.DeliveryPostcode || getCustomerInfo.value?.CustomerPostcode || '',
          validators: [minLength(1, 'Póstnúmer má ekki vera tómt'), validatePostCode(state.postCodes, 'Póstnúmer ekki löglegt')],
        },
        comment: {
          value: getDeliveryInfo.value.DeliveryComment || getCustomerInfo.value?.CustomerComment || '',
          validators: [],
        },
      });

      const selectDroppLocation = async () => {
        // eslint-disable-next-line no-undef
        const droppLocation = await droppHelper.default.getDroppLocation();
        // Set fields based on dropp location
        droppDeliveryInfo.DeliveryName = `Dropp, ${droppLocation?.name}`;
        droppDeliveryInfo.DeliveryAddress = droppLocation?.addressObject.street;
        droppDeliveryInfo.DeliveryPhone = '546 6100'; // Dropp service desk phone number
        droppDeliveryInfo.DeliveryPostcode = droppLocation?.addressObject.zip;
        droppDeliveryInfo.DeliveryCity = droppLocation?.addressObject.town;
        droppDeliveryInfo.DeliveryEmail = 'dropp@dropp.is'; // Dropp official email
        droppDeliveryInfo.DroppLocationID = droppLocation?.id;
        droppDeliveryInfo.DroppLocationName = `${droppLocation?.name}: ${droppLocation?.address}`;
        await setDeliveryInfo(droppDeliveryInfo);
      };

      const postShippingInfo = async () => {
        if (selectedShippingID.value === homeDeliveryID) {
          const deliveryInfo = {} as DeliveryInfo;
          deliveryInfo.DeliveryName = deliveryAddressInputs.name.value;
          deliveryInfo.DeliveryPhone = deliveryAddressInputs.phone.value;
          deliveryInfo.DeliveryAddress = deliveryAddressInputs.address.value;
          deliveryInfo.DeliveryCity = '';
          deliveryInfo.DeliveryEmail = '';
          deliveryInfo.DeliveryPostcode = deliveryAddressInputs.postcode.value;
          deliveryInfo.DeliveryComment = deliveryAddressInputs.comment.value;
          await postDeliveryInfo(deliveryInfo);
        } else if (selectedShippingID.value === postBoxID) {
          const deliveryInfo = {
            DeliveryName: selectedPostBox.value.Name,
            DeliveryAddress: selectedPostBox.value.Address,
            DeliveryCity: selectedPostBox.value.Town,
            DeliveryPostcode: selectedPostBox.value.Postcode,
            DeliveryPhone: deliveryAddressInputs.phone.value,
            DeliveryEmail: '',
            DeliveryComment: '',
            DroppLocationID: '',
            DroppLocationName: '',
          } as DeliveryInfo;
          await postDeliveryInfo(deliveryInfo);
        } else if (selectedShippingID.value === droppBoxID) {
          await postDeliveryInfo(droppDeliveryInfo);
        } else if (selectedShippingID.value === nearestPostOfficeID) {
          const deliveryInfo = {
            DeliveryName: getCustomerInfo.value?.CustomerName ?? '',
            DeliveryAddress: getCustomerInfo.value?.CustomerAddress ?? '',
            DeliveryCity: getCustomerInfo.value?.CustomerCity ?? '',
            DeliveryPostcode: getCustomerInfo.value?.CustomerPostcode ?? '',
            DeliveryPhone: getCustomerInfo.value?.CustomerPhone ?? '',
            DeliveryEmail: getCustomerInfo.value?.CustomerEmail ?? '',
            DeliveryComment: getCustomerInfo.value?.CustomerComment ?? '',
          } as DeliveryInfo;
          await postDeliveryInfo(deliveryInfo);
        } else if (selectedShippingMethodObject.value.AdvaniaIntegrated && selectedShippingMethodAgent.value !== null) {
          await selectAdvaniaShippingAgent(selectedShippingMethodObject.value, selectedShippingMethodAgent.value);
        } else {
          await postShippingMethod(selectedShippingID.value);
        }
      };

      function isFormValid() {
        if (cartLoading.value) {
          return false;
        }

        // Dropp
        // If selected as an option then valid if there is a dropp location value
        if (selectedShippingID.value === droppBoxID) {
          return (
            getDeliveryInfo.value.DroppLocationName !== '' &&
            getDeliveryInfo.value.DroppLocationName !== undefined &&
            getDeliveryInfo.value.DroppLocationID !== '' &&
            getDeliveryInfo.value.DroppLocationID !== undefined
          );
        }

        // Postbox
        // If selected as an option then valid if there is a selected postbox
        if (selectedShippingID.value === postBoxID) {
          return selectedPostBox?.value?.Name !== undefined && selectedPostBox?.value?.Name !== 'Velja póstbox';
        }

        // Home delivery
        // If selected as an option then valid if all input fields are valid
        if (selectedShippingID.value === homeDeliveryID) {
          const allDeliveryAddressInputs = Object.values(deliveryAddressInputs).every(
            (input) => input.validators.every((validator) => validator(input.value) === null) || input.validators.length === 0,
          );
          return allDeliveryAddressInputs;
        }

        // If nothing is selected
        if (selectedShippingID.value === undefined || selectedShippingID.value === '') {
          return false;
        }

        return true;
      }

      const formIsValid = computed(() => isFormValid());

      const isPostingShippingInfo = ref(false);
      const goToRoute = async (route: string) => {
        try {
          isPostingShippingInfo.value = true;
          await postShippingInfo();
        } catch (error) {
          console.log('error in CartShippingMethod.vue::submitForm');
          console.log(error);
        } finally {
          isPostingShippingInfo.value = false;
          router.push(route);
        }
      };

      const goBack = async () => {
        await goToRoute('/cart/contact-info');
      };

      const goForward = async () => {
        GA4E.addShippingInfo(orderLines.value, selectedShippingID.value);
        await goToRoute('/cart/payment-methods');
      };

      const displayName = (name: string) => {
        const prefix = /^(\[.*\])/;
        return !!name ? name.replace(prefix, '').trim() : 'Nafn tómt';
      };

      return {
        goBack,
        goForward,
        shippingMethods,
        selectShippingMethod,
        selectPostBox,
        selectedPostBox,
        setDeliveryInfo,
        getDeliveryInfo,
        deliveryAddressInputs,
        selectedShippingMethod,
        selectedShippingMethodObject,
        selectedShippingID,
        homeDeliveryID,
        droppBoxID,
        selectDroppLocation,
        formIsValid,
        cartLoading,
        selectAdvaniaShippingAgent,
        onAdvaniaShippingAgentSelect,
        selectedShippingMethodAgent,
        isPostingShippingInfo,
        displayName,
      };
    },
    data() {
      return {
        postBoxOpen: false,
        droppJS: null,
        // droppDeliveryInfo: {} as DeliveryInfo,
      };
    },
    mounted() {
      const droppLibrary = 'https://app.dropp.is/dropp-locations.min.js?data-store-id=dd117b05-ebf9-4e8c-a0aa-859aa44575ee';
      const droppJS = document.createElement('script');
      droppJS.setAttribute('src', droppLibrary);
      document.head.appendChild(droppJS);
    },
    methods: {
      selectingPostBox(shippingMethodID, postBoxName) {
        const payload: postboxPayload = {
          shippingID: shippingMethodID,
          postBoxID: postBoxName,
        };
        this.selectPostBox(payload);
        this.postBoxOpen = false;
      },
      postBoxDropDown() {
        this.postBoxOpen = !this.postBoxOpen;
      },
    },
  });
