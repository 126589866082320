
  import { defineComponent } from 'vue';
  import { RouteRecordNormalized } from 'vue-router';
  import { mapActions } from 'vuex';

  import { getAreaForPage } from '@/utils/routingHelper';
  import { ImageHandler } from '@/utils/imageHelper';
  import { ProductIsFavoritePayload } from '@/models/productList/productListPayloadModels';
  import useAuthenticateUser from '@/composables/useAuthenticateUser';

  import ProductFlags from './ProductFlags.vue';
  import useManageUserWishlist from '@/composables/useManageUserWishlist';
  import conf from '@/config';

  export default defineComponent({
    components: {
      ProductFlags,
    },
    props: ['productInfo', 'size', 'pathPrefix'],
    emits: ['onPress'],
    setup() {
      const { hasAuthenticatedUser } = useAuthenticateUser();

      const { toggleProductInWishlist } = useManageUserWishlist();

      return {
        hasAuthenticatedUser,
        toggleProductInWishlist,
      };
    },
    data() {
      return {
        area: {} as RouteRecordNormalized,
        cardSize: this.size,
        cardClass: `productCard--${this.size}`,
        imagePrefix: conf.dwUrl,
      };
    },
    computed: {
      product(): any {
        return this.productInfo;
      },
      hasDiscount(): boolean {
        return this.product?.Price?.PriceWithVat < this.product?.PriceBeforeDiscount?.PriceWithVat;
      },
      getDiscountPercent(): string {
        if (`${this.productInfo.Price?.DiscountPercent}`.length > 5) {
          return this.productInfo.Price?.DiscountPercent.toFixed(2);
        }

        return this.productInfo.Price?.DiscountPercent;
      },
      getRoutePrefix(): string {
        if (this.pathPrefix) {
          return this.pathPrefix;
        }
        if (this.area.path && this.area.path !== '/') {
          return this.area.path;
        }
        return '';
      },
    },
    mounted() {
      this.area = getAreaForPage(this.$router);
    },
    methods: {
      ...mapActions('product', ['setProduct']),
      onLinkClick(product: any) {
        this.$emit('onPress');
        this.$store.commit('product/setProduct', product);
      },
      isAltImage(product: any) {
        return product.ImagePatternImages.length > 1;
      },
      primaryImage(product: any) {
        let image = '';
        if (product.ImagePatternImages.length > 0) {
          image = product.ImagePatternImages[0]?.Value;
        } else {
          image = product.DefaultImage?.Value;
        }

        return this.imagePath(image, 500, 500);
      },
      alternativeImage(product: any) {
        let image = '';
        if (product.ImagePatternImages.length > 1) {
          image = product.ImagePatternImages[1]?.Value;
        } else if (product.ImagePatternImages.length > 0) {
          image = product.ImagePatternImages[0]?.Value;
        } else {
          image = product.DefaultImage?.Value;
        }

        return this.imagePath(image, 500, 500);
      },
      imagePath(image: string, width: number, height: number) {
        return this.imagePrefix + ImageHandler(image, width, height);
      },
      isNew() {
        if (this.product?.Created !== undefined) {
          const createdDate = new Date(this.product.Created);
          const tenDaysAgo = new Date();
          tenDaysAgo.setDate(new Date().getDate() - 10);
          return tenDaysAgo < createdDate;
        }
        return false;
      },
      toggleFavorite() {
        const payload = {
          productId: this.product.Id,
          isFavorite: this.product.IsFavorite,
        } as ProductIsFavoritePayload;
        this.toggleProductInWishlist(payload);
        // this.$store.dispatch(`auth/${AUTH_ACTIONS.TOGGLE_PRODUCT_IN_WISHLIST}`, payload);
      },
    },
  });
