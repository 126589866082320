
  import { defineComponent, PropType } from 'vue';
  import useParagraph from '@/composables/useParagraph';
  import { TextAndImage } from '@/models/item-types/paragraph';
  import conf from '@/config';

  export default defineComponent({
    components: {},
    props: {
      componentProps: {
        type: Object as PropType<unknown>,
        required: true,
      },
    },
    setup(props) {
      const { data, getParagraphStyles, getParagraphClasses } = useParagraph(props.componentProps);

      const paragraphData = data.value as unknown as TextAndImage;
      const imagePrefix = conf.dwUrl;
      const imagePath = paragraphData.image !== undefined ? paragraphData.image[0].Path : '';

      const videoHeight = paragraphData?.videoHeight > 0 ? `height: ${paragraphData?.videoHeight}px; ` : '';
      const videoWidth = paragraphData?.videoWidth > 0 ? ` width: ${paragraphData?.videoWidth}px;` : '';
      const videoStyles = videoHeight + videoWidth;

      return {
        paragraphData,
        getParagraphStyles,
        imagePrefix,
        imagePath,
        videoStyles,
        getParagraphClasses,
      };
    },
  });
