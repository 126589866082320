import { RouteLocationNormalizedLoaded, Router, RouteRecord, RouteRecordNormalized, RouteRecordRaw } from 'vue-router';
import { Field } from '@/models/field';

export function hasChildren(route: RouteRecord): boolean {
  return route?.children?.length > 0;
}

export function getLevel0Routes(router: Router, includeDisabled = false): RouteRecordRaw[] {
  const lvl0Routes = router.getRoutes().filter((route: RouteRecord) => route.meta.level === 0 && (includeDisabled || route.meta.disableInNav !== true));
  return lvl0Routes;
}

export function getChildren(route: RouteRecord): RouteRecordRaw[] {
  return route.children || [];
}

export function getPrimaryAreaForHostname(): string {
  let primaryArea: string;
  const hostname = window.location.host;

  switch (hostname) {
    case 's4s.is':
      primaryArea = 's4s';
      break;
    case 'ellingsen.s4s.is':
      primaryArea = 'ellingsen';
      break;
    case 'skor.s4s.is':
      primaryArea = 'skor';
      break;
    case 'air.s4s.is':
      primaryArea = 'air';
      break;
    case 'staging.ellingsen.is':
      primaryArea = 's4s';
      break;
    default:
      primaryArea = 'ellingsen';
      break;
  }

  return primaryArea;
}

export function getPrimaryArea(router: Router): RouteRecordNormalized {
  const primaryArea = getPrimaryAreaForHostname();
  return router.getRoutes().find((route: RouteRecord) => route.meta.level === 0 && route.path.startsWith(`/${primaryArea}`)) || ({} as RouteRecordNormalized);
}

export function getAreaForPage(router: Router): RouteRecord {
  const currentRoute = router.currentRoute.value;
  return router.getRoutes().find((r: RouteRecord) => r.meta.level === 0 && currentRoute.path.startsWith(r.path)) || ({} as RouteRecord);
}

export function getAreaForRoute(route: RouteRecord, router: Router): RouteRecord {
  return router.getRoutes().find((r: RouteRecord) => r.meta.level === 0 && route.path.startsWith(r.path)) || ({} as RouteRecord);
}

export function getFrontPageChild(route: RouteRecord): RouteRecordRaw {
  return route.children.find((child: RouteRecordRaw) => child.meta?.name === 'Frontpage') || ({} as RouteRecordRaw);
}

export function currentRouteIsCheckout(route: RouteLocationNormalizedLoaded): boolean {
  return route.path.includes('cart');
}

export function getRoutePathFromArea(area: any): string {
  const routePath = area?.Item?.Fields?.find((field: any) => field.SystemName === 'RoutePath');
  return routePath?.Value;
}

export function isFrontPage(route: RouteRecord): boolean {
  return route.meta?.name === 'Frontpage';
}

// This is necessary since DW does not convert Ð and ð
export function convertIcelandicLettersInUrl(url: string): string {
  return url.replaceAll('ð', 'd').replaceAll('Ð', 'D');
}

export function getRouteByName(router: Router, name: string): RouteRecordNormalized | undefined {
  return router.getRoutes().find((r: RouteRecord) => r.name === name);
}

export function getUserArea(router: Router): RouteRecordNormalized | undefined {
  return getRouteByName(router, 'Mínar síður');
}

export function getAreaItemFieldValue(fields: Array<Field>, fieldName: string): unknown {
  const field = (fields || []).find(({ Name }) => Name === fieldName);
  return field?.Value;
}
