
  /* eslint-disable no-undef */
  /* eslint-disable no-restricted-syntax */
  /* eslint-disable @typescript-eslint/no-explicit-any */
  import { defineComponent, h } from 'vue';
  import axios from 'axios';
  import { RouteRecordRaw } from 'vue-router';
  import { mapGetters, mapActions, mapState, useStore } from 'vuex';
  import { camelCase, get, keyBy } from 'lodash';
  import Navigation from '@/global_components/navigation/Navigation.vue';
  import Footer from '@/global_components/footer/Footer.vue';
  import Sprites from '@/global_components/Sprites.vue';
  import Loader from '@/components/shared/Loader.vue';
  import PageNotFoundVue from '@/global_components/PageNotFound.vue';
  import MasterComponentVue from '@/global_components/MasterComponent.vue';
  import BackToTop from '@/global_components/BackToTop.vue';
  import * as CONST from '@/constants/websiteSettings';
  import * as PAGE_CONST from '@/constants/pageSettings';
  import Login from '@/components/user/Login.vue';
  import useAuthenticateUser from '@/composables/useAuthenticateUser';
  import useNavigation from '@/composables/useNavigation';
  import { getAreaForPage, getAreaItemFieldValue, getPrimaryAreaForHostname, getRoutePathFromArea, getUserArea } from './utils/routingHelper';
  import useWebsiteSettings from './composables/useWebsiteSettings';
  import { WebsiteSettings, WebsiteStateSettings } from './models/store/website';
  import { isColorField, isFileField } from './utils/modelChecker';
  import { Field } from './models/field';
  import FacebookChat from '@/global_components/FacebookChat.vue';
  import config from '@/config';


  export default defineComponent({
    components: {
      Navigation,
      Footer,
      Loader,
      BackToTop,
      Sprites,
      Login,
      FacebookChat,
    },
    setup() {
      const { hideLoginForm, loadUser, isAuthenticated, hasAuthenticatedUser } = useAuthenticateUser();
      const { navigateToArea, navigateToPrimaryArea } = useNavigation();
      const { registerAreaSettings, websiteStyles } = useWebsiteSettings();

      const store = useStore();

      return {
        hideLoginForm,
        loadUser,
        isAuthenticated,
        hasAuthenticatedUser,
        navigateToArea,
        navigateToPrimaryArea,
        registerAreaSettings,
        websiteStyles,
        store,
      };
    },
    data() {
      return {
        routes: [] as RouteRecordRaw[],
      };
    },
    computed: {
      ...mapGetters('website', {
        websiteSettings: 'getWebsiteSettings',
      }),
      ...mapState('auth', ['displayLoginForm']),
      getAreaName() {
        const area = getAreaForPage(this.$router);
        const areaName = (area.name as string)?.toLowerCase().replaceAll('ó', 'o').replaceAll('ð', 'd');
        return areaName || 's4s';
      },
      getThemeArea() {
        const area = getAreaForPage(this.$router);
        const areaName = (area.path as string)?.substring(1);
        return areaName || 's4s';
      },
      isDevEnv() {
        return config.env === 'DEV';
      },
    },
    created() {
      this.subdomainRedirect();
      this.load404Route();
      this.loadRoutesFromBackend();
      this.loadCart();
      this.loadUser();
      this.authExpiredSetInterval();
      // this.loadPostCodes();
      // if (window.location.href.includes('brp-ellingsen')) {
      //   window.location.href = 'https://www.brp.is';
      // }
    },
    methods: {
      ...mapActions('cart', ['setPostCodes']),
      load404Route() {
        this.$router.addRoute({
          path: '/:pathMatch(.*)*',
          name: '404',
          component: PageNotFoundVue,
        });
      },
      async loadRoutesFromBackend() {
        const areas = (await axios.get(`${config.dwUrl}/dwapi/content/areas`)) as any;
        const pages = (await axios.get(`${config.dwUrl}/dwapi/content/pages`)) as any;
        for await (const area of areas.data) {
          const response = await axios.get(`${config.dwUrl}/dwapi/frontend/navigations/${area.ID}?StartLevel=1&StopLevel=4&ExpandMode=All`);
          const navigation = response.data;
          const children = this.extractChildren(
            navigation,
            area,
            keyBy(pages?.data || [], ({ ID }) => ID),
          );
          if (this.isActiveSite(area)) {
            const path = area.Item.Fields.find((field: any) => field.SystemName === CONST.SYSTEM_NAME.ROUTE_PATH).Value; // TODO: add fallback when website settings are missing
            const route = {
              path,
              name: area.Name,
              meta: {
                areaID: area.ID,
                level: 0,
                disableInNav: getAreaItemFieldValue(area.Item.Fields, 'DisableInNav') as boolean,
                isExternal: path.startsWith('http'),
              },
              component: MasterComponentVue,
              children,
            };
            this.$router.addRoute(route);
            this.registerAreaSettings(area.ID, this.extractAreaSettings(area));
          }
        }

        // Let area id default back to first area in list (witch is s4s area)
        const areaID = this.store.getters['website/getAreaID'];
        if(!areaID){
          this.store.dispatch('website/applySettings', areas.data[0].ID);
        }

        this.routes = this.$router.getRoutes();
        this.loadRouteAfterReload();
      },
      extractChildren(node: any, area: any, pageLookupMap: any): any[] {
        return node.Nodes.map((page) => ({ content: get(pageLookupMap, page.PageId, {}), ...page })).reduce((acc: any, page: any) => {
          if (this.isDisabled(page)) return acc;

          let pageLink = '';
          if (page.Name === 'Frontpage') pageLink = getRoutePathFromArea(area);
          else pageLink = page.Link;

          const children = page.Nodes && page.Nodes.length > 0 ? this.extractChildren(page, area, pageLookupMap) : [];
          acc.push({
            path: this.convertIcelandicLettersInUrl(pageLink),
            name: this.wrapPageNameWithLink(page),
            meta: {
              pageID: page.PageId,
              level: page.Level,
              name: page.Name,
              hidden: this.isHiddenPage(page),
              isExternal: pageLink.startsWith('http'),
              toggleDarkMode: page.content.PropertyItem?.Fields[2].Value,
            },
            component: MasterComponentVue,
            props: {
              children,
            },
            children,
          });
          return acc;
        }, [] as any[]);
      },
      isActiveSite(area: any) {
        return area?.Item?.Fields?.find((field) => field.SystemName === 'Active')?.Value;
      },
      loadRouteAfterReload() {
        if (this.$route.path === '' || this.$route.path === '/') {
          const primaryArea = getPrimaryAreaForHostname();
          const defaultPath = `/${primaryArea}`;
          this.$router.push(this.$router.getRoutes().find((route) => route.path === defaultPath) || this.$route);
        } else {
          this.$router.push(this.$route);
        }
      },
      // This is necessary since DW does not convert Ð and ð
      convertIcelandicLettersInUrl(url: string): string {
        return url.replaceAll('ð', 'd').replaceAll('Ð', 'D');
      },
      wrapPageNameWithLink(node: any): string {
        return `[${node.Link}]${node.Name}`;
      },
      isDisabled(page: any) {
        return (
          page.Name.toLowerCase() === 'footer' ||
          get(page, 'content.PropertyItem.Fields', []).find(({ SystemName }) => SystemName === PAGE_CONST.SYSTEM_NAME.DISABLE_URL)?.Value === true
        );
      },
      isHiddenPage(page: any) {
        return (
          page.Name.toLowerCase() === 'vara' ||
          get(page, 'content.PropertyItem.Fields', []).find(({ SystemName }) => SystemName === PAGE_CONST.SYSTEM_NAME.HIDE_IN_MENU)?.Value === true
        );
      },
      extractAreaSettings(area: any): WebsiteStateSettings {
        return {
          areaID: area.ID,
          areaName: area.Name,
          websiteSettings: area.Item?.Fields.reduce((acc, field: Field) => {
            const key = field.SystemName as keyof WebsiteSettings;
            if (isFileField(field)) {
              acc[camelCase(key)] = `${config.dwUrl}/${field.Value[0].Path}`;
            } else if (isColorField(field)) {
              acc[camelCase(key)] = field.Value.Hex;
            } else {
              acc[camelCase(key)] = field.Value;
            }
            return acc;
          }, {}),
        } as WebsiteStateSettings;
      },
      async loadPostCodes() {
        this.setPostCodes();
      },
      async loadCart() {
        this.$store.dispatch('cart/getCart');
        this.$store.dispatch('cart/getGiftWrapProduct');
      },
      subdomainRedirect() {
        const hostname = window.location.host;

        if (hostname === 'skor.is') window.location.href = 'https://s4s.is/skor';
        if (hostname === 'skor.s4s.is') window.location.href = 'https://s4s.is/skor';
        if (hostname === 'air.is') window.location.href = 'https://s4s.is/air';
        if (hostname === 'air.s4s.is') window.location.href = 'https://s4s.is/air';
        if (hostname === 'ellingsen.is') window.location.href = 'https://s4s.is/ellingsen';
        if (hostname === 'ellingsen.s4s.is') window.location.href = 'https://s4s.is/ellingsen';
      },
      onLoginCancel() {
        const targetArea = getAreaForPage(this.$router);
        const userArea = getUserArea(this.$router);

        this.hideLoginForm();

        if (targetArea?.meta?.areaID === get(userArea || {}, 'meta.areaID', undefined)) {
          this.navigateToPrimaryArea();
        }
      },
      onLogin() {
        this.hideLoginForm();
      },
      authExpiredSetInterval() {
        setInterval(() => {
          if (this.hasAuthenticatedUser) {
            this.isAuthenticated();
          }
        }, 60000);
      },
    },
  });
