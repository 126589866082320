import { assign } from 'lodash';
import { MANUFACTURER_PROPERTIES, PRODUCT_PROPERTIES } from '@/constants/product';
import { GroupRequest } from '@/models/group/group';
import { ProductListPayload } from '@/models/productList/productListPayloadModels';
import conf from '@/config';

export function getProductListSearchParams(payload: ProductListPayload): URLSearchParams {
  const params = new URLSearchParams();
  params.append('CurrentPage', payload.params.pageNumber);
  params.append('PageSize', payload.params.pageSize);
  params.append('ShopID', conf.shop);
  params.append('CountryCode', 'IS');
  params.append('CurrencyCode', 'ISK');
  params.append('FilledProperties', 'Products,PageCount,CurrentPage,TotalProductsCount,FacetGroups');
  params.append('ProductSettings.FilledProperties', PRODUCT_PROPERTIES);
  params.append('ProductSettings.ManufacturerSettings.FilledProperties', MANUFACTURER_PROPERTIES);
  params.append('FacetGroupSettings.FacetGroupNames', payload.params.facetName);
  return params;
}

export function getProductSearchParams(): URLSearchParams {
  const params = new URLSearchParams();
  params.append('ShopID', conf.shop);
  params.append('CountryCode', 'IS');
  params.append('FilledProperties', PRODUCT_PROPERTIES);
  params.append('ManufacturerSettings.FilledProperties', MANUFACTURER_PROPERTIES);
  params.append('CurrencyCode', 'ISK');
  return params;
}

export function getRelatedProductsSearchParams(): URLSearchParams {
  const params = new URLSearchParams();
  params.append('ShopID', conf.shop);
  params.append('CountryCode', 'IS');
  params.append('CurrencyCode', 'ISK');
  params.append('FilledProperties', 'Products,TotalProductsCount');
  params.append('ProductSettings.FilledProperties', `,${PRODUCT_PROPERTIES},VariantInfo`);
  params.append('ProductSettings.ManufacturerSettings.FilledProperties', MANUFACTURER_PROPERTIES);
  return params;
}

export function getProductVariantsSearchParams(): URLSearchParams {
  const params = new URLSearchParams();
  params.append('ShopID', conf.shop);
  params.append('CountryCode', 'IS');
  params.append('CurrencyCode', 'ISK');
  params.append('FilledProperties', 'VariantInfo,Id,Number');
  return params;
}

export function getProductFieldSearchParams(): URLSearchParams {
  // TODO - FIX LOOPEDYLOOP
  const params = new URLSearchParams();
  params.append('ShopID', conf.shop);
  params.append('CountryCode', 'IS');
  params.append('CurrencyCode', 'ISK');
  params.append('FilledProperties', 'Id,Number,ProductFields');
  return params;
}

export function createFilterParameterUrl(selectedFacetOptions: string[]): string {
  let filterString = '';
  const queryParameters = [] as string[];

  const facetOptionsMap = selectedFacetOptions.map((item) => item.split('_'));
  facetOptionsMap.forEach((facetOption: string[]) => {
    const queryParameter = facetOption[0];
    if (queryParameters.indexOf(queryParameter) === -1) {
      queryParameters.push(queryParameter);
    }
  });

  queryParameters.forEach((queryParameter: string) => {
    let paramValues = '';
    facetOptionsMap.forEach((facetOption: string[]) => {
      if (queryParameter === facetOption[0]) {
        paramValues += paramValues.length > 0 ? `,${facetOption[1]}` : `${facetOption[1]}`;
      }
    });

    if (paramValues.length > 0) {
      filterString += `&${queryParameter}=`;
      filterString += paramValues;
    }
  });

  return filterString;
}

export function getGroupSearchParams(merge?: GroupRequest): GroupRequest {
  return assign({ ShopId: conf.shop } as GroupRequest, merge);
}
