
  import { computed, defineComponent, reactive, ref } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import TextInput from '@/components/shared/inputs/TextInput.vue';
  import CheckboxInput from '@/components/shared/inputs/CheckboxInput.vue';
  import CartBackAndNextButtons from '@/components/cart/checkout/shared/CartBackAndNextButtons.vue';
  import { minLength, validateEmail, validatePhoneNumber, validatePostCode, validateSSN, validateSSNOptional } from '@/utils/formValidators';
  import { CartState } from '@/models/store/cart';
  import useCart from '@/composables/useCart';
  import config from '@/config';
  import useWebsiteSettings from '@/composables/useWebsiteSettings';

  export default defineComponent({
    components: {
      TextInput,
      CheckboxInput,
      CartBackAndNextButtons,
    },
    setup() {
      const formId = 'cartContactInfoForm_Form_formid';
      const router = useRouter();
      const store = useStore();
      const state = store.state.cart as CartState;
      const { websiteSettings } = useWebsiteSettings();

      const {
        giftWrap,
        mailingList,
        useOtherAddress,
        toggleGiftWrap,
        toggleMailingList,
        toggleUseOtherAddress,
        postContactInfo,
        setContactInfo,
        getContactInfo,
        partialCartUpdate,
        giftWrapProduct,
        giftWrapOrderLine,
        isLoading,
      } = useCart();

      const updatingContactInfo = ref(false);
      const formIsSubmitted = ref(false);
      const termsAndConditionsError = ref(false);

      const nameRef = ref<typeof TextInput>();
      const addressRef = ref<typeof TextInput>();
      const postcodeRef = ref<typeof TextInput>();
      const phoneRef = ref<typeof TextInput>();
      const emailRef = ref<typeof TextInput>();
      const isShared = config.area === 'shared';

      const ssnValidators = isShared
        ? [validateSSNOptional('Kennitala ekki lögleg')]
        : [minLength(1, 'Kennitala má ekki vera tómt'), validateSSN('Kennitala ekki lögleg')];

      const ssnLabel = isShared ? 'Kennitala Kaupanda' : 'Kennitala Kaupanda*';

      const inputs = reactive({
        name: {
          value: getContactInfo.value.CustomerName,
          validators: [minLength(1, 'Nafn má ekki vera tómt')],
        },
        ssn: {
          value: getContactInfo.value.CustomerSSN,
          validators: ssnValidators,
        },
        address: {
          value: getContactInfo.value.CustomerAddress,
          validators: [minLength(1, 'Heimilisfang má ekki vera tómt')],
        },
        postcode: {
          value: getContactInfo.value.CustomerPostcode,
          validators: [minLength(1, 'Póstnúmer má ekki vera tómt'), validatePostCode(state.postCodes, 'Póstnúmer ekki löglegt')],
        },
        phone: {
          value: getContactInfo.value.CustomerPhone,
          validators: [minLength(1, 'Símanúmer má ekki vera tómt'), validatePhoneNumber('Símanúmer ekki löglegt')],
        },
        email: {
          value: getContactInfo.value.CustomerEmail,
          validators: [minLength(1, 'Netfang má ekki vera tómt'), validateEmail('Netfang ekki löglegt')],
        },
        company: {
          value: getContactInfo.value.CustomerCompany,
          validators: [],
        },
        comment: {
          value: getContactInfo.value.CustomerComment,
          validators: [],
        },
      });

      const checkboxes = reactive({
        saveUserInfo: false,
        termsAndConditionsAccepted: false,
      });

      const formIsValid = computed(() => {
        if (!formIsSubmitted.value) {
          return true;
        }

        const allInputsValid = Object.values(inputs).every(
          (input) => input.validators.every((validator) => validator(input.value) === null) || input.validators.length === 0,
        );
        // const allOtherAddressInputsValid = Object.values(otherAddressInputs).every((input) => input.validators.every((validator) => validator(input.value) === null) || input.validators.length === 0);
        const { termsAndConditionsAccepted } = checkboxes;
        if (!useOtherAddress.value) {
          return allInputsValid && termsAndConditionsAccepted;
        }
        return allInputsValid && termsAndConditionsAccepted; // allOtherAddressInputsValid
      });

      const goBack = () => {
        router.push('/cart/overview');
      };

      const goForward = () => {
        if (giftWrap.value) {
          // router.push('/cart/gift-wrap');
          // This is temporary until gift wrap page is fully developed, this was due to unrealistic release schedule.
          router.push('/cart/delivery-options');
        } else {
          router.push('/cart/delivery-options');
        }
      };

      const validateInputs = (): boolean => {
        [nameRef, addressRef, postcodeRef, phoneRef, emailRef].forEach((r) => {
          const { value } = r;
          if (value?.validate) {
            value.validate();
          }
        });

        termsAndConditionsError.value = !checkboxes.termsAndConditionsAccepted;

        return (
          Object.values(inputs).every((input) => input.validators.every((validator) => validator(input.value) === null) || input.validators.length === 0) &&
          !termsAndConditionsError.value
        );
      };

      const onUpdateTermsAndConditions = (): void => {
        termsAndConditionsError.value = !checkboxes.termsAndConditionsAccepted;
      };

      const submitForm = async () => {
        formIsSubmitted.value = true;
        if (validateInputs()) {
          try {
            const form = document.getElementById(formId) as HTMLFormElement;
            const formData = new FormData(form);
            updatingContactInfo.value = true;
            const cartResponse = await postContactInfo(formData);
            partialCartUpdate(cartResponse.data, ['OrderLines', 'General']);
          } catch (error) {
            console.log('error in CartContactInfoForm.vue::submitForm');
            console.log(error);
          } finally {
            updatingContactInfo.value = false;
            goForward();
          }
        }
      };

      return {
        inputs,
        checkboxes,
        formIsValid,
        goBack,
        toggleGiftWrap,
        toggleMailingList,
        toggleUseOtherAddress,
        giftWrap,
        mailingList,
        useOtherAddress,
        submitForm,
        goForward,
        updatingContactInfo,
        setContactInfo,
        getContactInfo,
        termsAndConditionsError,
        onUpdateTermsAndConditions,
        nameRef,
        addressRef,
        postcodeRef,
        phoneRef,
        emailRef,
        giftWrapProduct,
        giftWrapOrderLine,
        isLoading,
        formId,
        websiteSettings,
        ssnLabel,
      };
    },
  });
